/* * {
    transition: 0.5s;
  } */
  
  /* @keyframes turner {

    0% {
      transform: rotateY(0deg);
    }
    10% {
      transform: rotateY(0deg);
    }
    50% {
      transform: rotateY(360deg);
    }
    60% {
      transform: rotateY(360deg);
    }
    100% {
      transform: rotateY(0deg);
    }
    100% {
      transform: rotateY(0deg);
    }
  }
  .container {
    position: relative;
    perspective: 800px;
    margin: 0 auto;
    width: 50%;
  }
  .wrapper {
    width: 400px;
    height: 400px;
    position: absolute;
    transform-style: preserve-3d;
    transition: transform 1s;
  }
  
  .divs {
    width: 400px;
    height: 10px;
    background-image: url(http://www.bell.ca/Styles/wireless/all_languages/all_regions/catalog_images/Pixel_by_Google/google_pixel_very_silver_lrg1.png);
    background-size: cover;
    backface-visibility: visible;
    animation: turner 20s infinite;
  }
  .one {
    background-position: 0 0;
  }
  .one2 {
    background-position: 0 -100%;
    animation-delay: 0.1s;
  }
  .one3 {
    background-position: 0 -200%;
    animation-delay: 0.2s;
  }
  .one4 {
    background-position: 0 -300%;
    animation-delay: 0.3s;
  }
  .one5 {
    background-position: 0 -400%;
    animation-delay: 0.4s;
  }
  .one6 {
    background-position: 0 -500%;
    animation-delay: 0.5s;
  }
  .one7 {
    background-position: 0 -600%;
    animation-delay: 0.6s;
  }
  .one8 {
    background-position: 0 -700%;
    animation-delay: 0.7s;
  }
  .one9 {
    background-position: 0 -800%;
    animation-delay: 0.8s;
  }
  .one10 {
    background-position: 0 -900%;
    animation-delay: 0.9s;
  }
  .one11 {
    background-position: 0 -1000%;
    animation-delay: 1s;
  }
  .one12 {
    background-position: 0 -1100%;
    animation-delay: 1.1s;
  }
  .one13 {
    background-position: 0 -1200%;
    animation-delay: 1.2s;
  }
  .one14 {
    background-position: 0 -1300%;
    animation-delay: 1.3s;
  }
  .one15 {
    background-position: 0 -1400%;
    animation-delay: 1.4s;
  }
  .one16 {
    background-position: 0 -1500%;
    animation-delay: 1.5s;
  }
  .one17 {
    background-position: 0 -1600%;
    animation-delay: 1.6s;
  }
  .one18 {
    background-position: 0 -1700%;
    animation-delay: 1.7s;
  }
  .one19 {
    background-position: 0 -1800%;
    animation-delay: 1.8s;
  }
  .one20 {
    background-position: 0 -1900%;
    animation-delay: 1.9s;
  }
  .one21 {
    background-position: 0 -2000%;
    animation-delay: 2s;
  }
  .one22 {
    background-position: 0 -2100%;
    animation-delay: 2.1s;
  }
  .one23 {
    background-position: 0 -2200%;
    animation-delay: 2.2s;
  }
  .one24 {
    background-position: 0 -2300%;
    animation-delay: 2.3s;
  }
  .one25 {
    background-position: 0 -2400%;
    animation-delay: 2.4s;
  }
  .one26 {
    background-position: 0 -2500%;
    animation-delay: 2.5s;
  }
  .one27 {
    background-position: 0 -2600%;
    animation-delay: 2.6s;
  }
  .one28 {
    background-position: 0 -2700%;
    animation-delay: 2.7s;
  }
  .one29 {
    background-position: 0 -2800%;
    animation-delay: 2.8s;
  }
  .one30 {
    background-position: 0 -2900%;
    animation-delay: 2.9s;
  }
  .one31 {
    background-position: 0 -3000%;
    animation-delay: 3s;
  }
  .one32 {
    background-position: 0 -3100%;
    animation-delay: 3.1s;
  }
  .one32 {
    background-position: 0 -3100%;
    animation-delay: 3.1s;
  }
  .one33 {
    background-position: 0 -3200%;
    animation-delay: 3.2s;
  }
  .one34 {
    background-position: 0 -3300%;
    animation-delay: 3.3s;
  }
  .one35 {
    background-position: 0 -3400%;
    animation-delay: 3.4s;
  }
  .one36 {
    background-position: 0 -3500%;
    animation-delay: 3.5s;
  }
  .one37 {
    background-position: 0 -3600%;
    animation-delay: 3.6s;
  }
  .one38 {
    background-position: 0 -3700%;
    animation-delay: 3.7s;
  }
   */


   @keyframes turner{
    0%   { transform:rotateY(0deg); }
    10%   { transform:rotateY(0deg); }
    50% { transform:rotateY(360deg);}
    60% { transform:rotateY(360deg);}
    100% { transform:rotateY(0deg);}
    100% { transform:rotateY(0deg);}
  }
.popLoading{
    width: 100%;
	height: 100%;
	position: fixed;
	background: rgba(0, 0, 0, 0.347);
	top: 0;
	left: 0;
	z-index: 9999;
    align-items: center;
    justify-content: center;
}
  .container {
    width: 100%;
	height: 100%;
  }
  .container .imgLoad {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    transition: 0.5s;
    animation: turner 15s infinite;
  }
 /*  .container:hover img:nth-child(4) {
    transform: translate(160px, -160px);
    opacity: 1;
  }
  .container:hover img:nth-child(3) {
    transform: translate(120px, -120px);
    opacity: 0.8;
  }
  .container:hover img:nth-child(2) {
    transform: translate(80px, -80px);
    opacity: 0.6;
  }
  .container:hover img:nth-child(1) {
    transform: translate(40px, -40px);
    opacity: 0.4;
  } */
  