.events-date {
    text-align: center;
    position: absolute;
    right: 30px;
    top: 30px;
    background-color: rgba(25, 47, 89, 0.9);
    color: #fff;
    padding: 12px 20px 8px 20px;
    text-transform: uppercase
}

.event-time li {
    display: inline-block;
    margin-right: 20px
}

.event-time li:last-child {
    margin-right: 0
}

.event-time li i {
    color: #59c17a
}

.event-block {
    box-shadow: 0px 0px 16px 0px rgba(187, 187, 187, 0.48)
}

.event-block ul li i {
    color: #59c17a
}

@media screen and (max-width: 1199px) {
    .event-date {
        padding: 10px 18px 6px 18px
    }
}

@media screen and (max-width: 575px) {
    .event-date {
        padding: 8px 15px 4px 15px
    }
    .events-date {
        padding: 10px 15px 6px 15px
    }
}

.position-relative {
    position: relative !important;
}

.margin-40px-bottom {
    margin-bottom: 40px;
}
.padding-60px-lr {
    padding-left: 60px;
    padding-right: 60px;
}

.margin-15px-bottom {
    margin-bottom: 15px;
}
.font-weight-500 {
    font-weight: 500;
}
.font-size22 {
    font-size: 22px;
}
.text-theme-color {
    color: #192f59;
}
.margin-10px-bottom {
    margin-bottom: 10px;
}
.margin-10px-right {
    margin-right: 10px;
}