.pengadaan{
    flex: 5;
    margin: 50px 5px 5px 5px;
    display: flex;
    flex-direction: column;
}

.pengadaan-item-top {
    display: flex;
    background-color: #f1f1f1;
    text-align: right;
    height: 40px;
    justify-content: space-between;
}
.opt-Select{
    min-width: 200px;
    height: 30px;
    text-align: left;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    color: var(--blue);
    border-color: var(--blue);
}
.pengadaan-item-top-one {
    background-color: #f1f1f1;
    height: 40px;
}
.pengadaan-item-top-one select{
    height: 30px;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    color: var(--blue);
    border-color: var(--blue);
    align-items: center;
}

.pengadaan-item-top-one Button{
    height: 30px;
    border-radius: 5px;
    padding: 0;
    margin-left: 20px;
}

.pengadaan-item-top button{
    border: none;
    background-color: var(--blue);
    color: var(--white);
    padding: 8px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 13px;
}

.pengadaan-item-top button:hover{
    border: 1px solid var(--blue);
    background-color: var(--white);
    color: var(--blue);
}

.pengadaan-item-center {
    height: calc(100% - 90px);
    width: 100%;
}

.pengadaan-item-create {
    height: calc(100% - 90px);
    width: 100%;
    padding: 10px;
    margin-bottom: 30px;
}

.pengadaan-item-bottom {
    margin-top: 8px;
    background-color: dodgerblue;
    padding: 10px;
    height: 50px;
    width: 100%;
}

.containerStyle{
    width: 100%;
    height: 100%;
}

.pengadaan-item-center .ag-theme-alpine {
    width: 100%;
    height: 100%;
    --ag-foreground-color: #000000;
    --ag-background-color: #ffffff;
    --ag-header-foreground-color: #000000;
    --ag-header-background-color: var(--blue2);
    --ag-odd-row-background-color: var(--blue1);
    --ag-header-column-resize-handle-color: #000000;
    --ag-font-family: monospace;
    --ag-row-border-color : #c5c5c5a6;
    --ag-row-hover-color : #9398ff5c;
  
    --ag-font-size: 12px;
}
.box-btn{
    background-color: aqua;
    width: 100px;
    padding-bottom: 10px;
    padding-top: 2px;
}
.buttonSet{
    border: none;
    background-color: var(--blue);
    color: var(--white);
    cursor: pointer;
    padding: 8px;
    border-radius: 5px;
    margin-left: 10px;
}

.buttonCancel{
    border: none;
    background-color: var(--red);
    color: var(--white);
    cursor: pointer;
    padding: 8px;
    border-radius: 5px;
    margin-left: 10px;
}
.buttonReset{
    border: none;
    background-color: var(--green);
    color: var(--white);
    cursor: pointer;
    padding: 8px;
    border-radius: 5px;
    margin-left: 15px;
}

.buttonPrint{
    border: none;
    background-color: var(--violet);
    color: var(--white);
    cursor: pointer;
    padding: 8px;
    border-radius: 5px;
    margin-left: 10px;
    margin-right: 5px;
}

.buttonRead{
    border: none;
    background-color: rgba(254, 136, 0, 0.877);
    color: var(--white);
    cursor: pointer;
    padding: 8px;
    border-radius: 5px;
    margin-left: 10px;
    margin-right: 10px;
}

.buttonDel{
    border: none;
    background-color: var(--red);
    color: var(--white);
    cursor: pointer;
    padding: 8px;
    border-radius: 5px;
    margin-left: 10px;
}

/* btn untuk table pengadaan */
.btnPengView{
    border: none;
    background-color: var(--darkblue);
    color: var(--white);
    cursor: pointer;
    padding: 8px;
    border-radius: 5px;
    margin-left: 0px;
}

.btnPengVerify{
    border: none;
    background-color: var(--blue);
    color: var(--white);
    cursor: pointer;
    padding: 8px;
    border-radius: 5px;
    margin-left: 10px;
}

.btnPengDel{
    border: none;
    background-color: var(--red);
    color: var(--white);
    cursor: pointer;
    padding: 8px;
    border-radius: 5px;
    margin-left: 10px;
}
/* ****************************** */

.btnFilter{
    width: 100%;
    border: none;
    background-color: var(--green);
    color: var(--white);
    cursor: pointer;
    padding: 8px;
    border-radius: 5px;    
}

.btnFilter:hover{
    background-color: var(--white);
    color: var(--green);   
    border: 1px double var(--green);
}

.Status-Pengajuan{
    background: var(--red);
    color: var(--white);
    text-align: center;
    align-items: center;
    justify-content: center;
}

.table-ast{
	width: 100%;
	font-size: 12px;
	border-collapse: collapse;
    align-items: center;
    text-align: center;
}
.table-ast thead tr th{
	border: 0.2px solid #000000;
	padding: 2px;
	border: 0.5px solid #000000;
    background-color: var(--black2);
}
.table-ast tbody tr td{
	border: 0.2px solid #000000;
	padding: 2px;
	text-align: center;
}



@media screen and (max-width: 991px){
    .containerStyle{
        width: 500;
        height: 500;
    }
}

@media screen and (max-width: 475px){
    .pengadaan-item-top {
        display: block;
    }
}

