.box-log{
	width: 100%; 
	height: 100%;
	position: fixed;
	background: #0313f4bb;
	top: 0;
	left: 0;
	z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
}
.cont-box{
    position: relative;
    width: 800px;
    height: 500px;
    margin: 20px; 
}
.aw-box{
    position: absolute;
    top: 50px;
    width: 100%;
    height: 420px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 5px 45px rgba(0, 0, 0, 0.149);
}
.box-log.active{
    background: #f43649be;
}
.aw-box .bosx{
  position: relative;
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.aw-box .bosx h1{
  color: #fff;
  font-size: 1.2em;
  font-weight: 500;
  margin-bottom: 10px;

}
.aw-box .bosx button{
  cursor: pointer;
  padding: 10px 20px;
  background: #fff;
  color: rgb(0, 0, 0);
  font-size: 16px;
  font-weight: 500;
  border: 1px solid #0313f4de;
  border-radius: 10px;
}

.is-form{
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background: #fff;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 5px 45px rgba(0, 0, 0, 0.25);
    transition: 0.1s ease-in-out;
    border-radius: 16px;
    overflow: hidden;
}
.is-form.active{
  left: 50%;
}
.is-form .form{
  position: absolute;
  left: 0;
  width: 100%;
  padding: 50px;
  transition: 0.1s;
}
.is-form .close-button {
  width: 45px;
  height: 45px;
  display: block;
  text-align: center;
  color: #000000;
  text-decoration: none;
  position: absolute;
  top: 5px;
  right: -5px;
  cursor: pointer;
  font-size: 20px;
}
.is-form .FormSignin{
  transition-delay: 0.25s;
}
.is-form.active .FormSignin{
  left: -100%;
  transition-delay: 0s;
}
.is-form .FormSignup{
  left: 100%;
  transition-delay: 0s;
}
.is-form.active .FormSignup{
  left: 0;
  transition-delay: 0.25s;
}
.is-form .form form{
  width: 100%;
  display: flex;
  flex-direction: column;
}
.is-form .form form h3{
  font-size: 1.5em;
  color: #333;
  margin-bottom: 20px;
  font-weight: 500;
  align-items: center;
}
.is-form .form form label{
  font-size: 0.7em;
  margin-left: 8px;
  color: rgb(255, 0, 0);
}
.is-form .form form input{
  width: 100%;
  padding: 10px;
  outline: none;
  font-size: 16px;
  border: 1px double #333;
  border-radius: 10px;
}
.is-form .form form input[type="submit"]{
  background: #0313f4;
  border: none;
  color: #fff;
  max-width: 100px;
  cursor: pointer;

}
.is-form .form form input[type="submit"]:hover{
  background: #fff;
  color: #0313f4;
  border: 1px solid #0313f4;

}
.is-form.active .FormSignup input[type="submit"]{
  background: #f43649be;
}

.hide {
    display: none;
}
  
.valid {
    color: limegreen;
    margin-left: 0.25rem;
}

.invalid {
    color: red;
    margin-left: 0.25rem;
}