.divisi{
    margin-top: 40px;
    flex: 5;
}

.divisiCrump{
    margin: 20px;
}

.divisiWidgets{
    display: flex;
}

.divisiWidgets .icon{
    position: relative;
    display: block;
}

.divisiWidgets .icon i{
    color: var(--blue);
    font-size: 5em;
    text-align: center;
    align-items: center;
}

.link{
    width: 15rem;
    text-decoration: none;
    color: inherit;
}

@media screen and (max-width: 991px){
    .link{
        width: 100%;
    }
}


/* .card {
    background: #fff;
    margin: 0.5em;
    overflow: hidden;
    cursor: pointer;
    transition: all ease 200ms;
}

.card:hover {
    transform: scale(1.03);
    border-color: #eee;
    box-shadow: 0 13px 40px -5px hsla(253, 100%, 50%, 0.12), 0 8px 32px -8px hsla(0, 0%, 100%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 100%, 0.02);
} */

.card .icon{
    position: relative;
    display: block;
}

.card .icon i{
    color: var(--blue);
    font-size: 5em;
    text-align: center;
    font-weight: bold;
    align-items: center;
}

.card-text{
    font-weight: bold;
}