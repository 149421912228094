:root{
  --blue: #287bff;
  --blue1: #287aff4e;
  --blue2: #287affbe;
  --blue3: #120cce;
  --darkblue : #082A54;
  /* --blue3: #120cce60; */
  --white: #fff;
  --grey: #f5f5f5;
  --black1: #222;
  --black2: #999;
  --black3: #dfdfdf;
  --red: #800000;
  --red1: #c41414;
  --red2: #E02B35;
  --green: #59A89C;
  /* --green: #38cc4c73; */
  
  --violet: #7a0080;
  --violet1: #a35ea6c4;
  --yellow: #c8db1f;
  --gold: #F0C571;
  --grays: #d8dadd;
}

button {
  border: 0;
  background-color: transparent;
}

.setContain{
  margin: 30px 10px 0 65px;
}

.nmTeks {
  cursor: pointer;
}

.nmTeks:hover{
  color: #0d0db1;
  border-bottom: 1px solid #0d0db1;
}

/************ Navbar Css ************/
nav.navbar {
  width: 100%;
  margin: auto;
  display: flex;
  position: sticky;
  position: -webkit-sticky; 
  top: 0;
  background: #FFFFFF;
  z-index: 1;
  box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.2), -1px -1px 8px rgba(0, 0, 0, 0.2);
}
nav.navbar.scrolled {
  padding: 10px 0 10px 0;
}

nav.navbar .navbar-nav .nav-link.navbar-link {
  justify-content: center;
  font-weight: bold;
  letter-spacing: 0.8px;
  opacity: 0.75;
  margin-left: 8px;
}
nav.navbar .navbar-nav a.nav-link.navbar-link:hover{
    opacity: 1;
    color: #120cce;

}
nav.navbar .navbar-nav a.nav-link.navbar-link.active{
  border-bottom: 3px solid #ffffff;
}

nav.navbar .navbar-nav a.nav-link.navbar-link.active {
  opacity: 1;
  color: #120cce;
  
}

.navbar-text button {
  font-weight: 700;
  color: #b2b2b2;
  border: 1px solid #00139f;
  padding: 5px 10px;
  font-size: 18px;
  margin-left: 18px;
	position: relative;
	background-color: transparent;
	transition: 0.3s ease-in-out;
}
.navbar-text button span {
  z-index: 1;
}
.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #00139f;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.navbar-text button:hover {
  color: #ffffff;
}
.navbar-text button:hover::before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
}

/************ Banner Css ************/
.banner {
  margin-top: 0;
  padding: 150px 0 100px 0;
  background-image: url('./assets/images/wae1.svg');
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}

.banner img {
  animation: updown 10s linear infinite;
}

@keyframes updown {
  0% {
      transform: translateY(-20px);
  }
  50% {
      transform: translateY(20px);
  }
  100% {
      transform: translateY(-20px);
  }
}

.imgFil {
  width: 100%;
  height: auto;
}

.footer {
  padding: 0 0 50px 0;
  background-color: #04009A;
}

.footer p {
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 0.5px;
  margin-top: 30px;   
}

.clr-Pengajuan {
  background-color: #d07979a7 !important;
}
.clr-Progress {
  background-color: #e7d32260 !important;
  /* color: #918413; */
}
.clr-Selesai {
  background-color: #38cc4c73 !important;
}
.clr-Revisi {
  background-color: #a35ea6c4 !important;
}
.clr-Verify {
  background-color: #120cce60 !important;
}
.clr-nul {
  background-color: #ffffff !important;
}


@media screen and (max-width: 1021px){
  .setContain{
    margin: 30px 10px 0 10px;
  }
}

/* 
.setContain{
  margin: 30px 10px 0 65px;
}
*/

.tippy-container {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  position: absolute;
  z-index: 1000;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.tippy-item:hover {
  cursor: pointer;
  background: rgb(240, 240, 240);
}

.tippy-item.active {
  background-color: #dc3545;
  border-color: #dc3545;
  color: white;
}
.tippy-item {
  display: block;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  border: 0;
}

.btntippy {
  color: #000000;
  background-color: #ffffff00;
  border-color: #ffffff00;
  display: inline-block;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  font-size: 1rem;
  border-radius: 0.25rem;
  padding: 0;
  text-align: left;
}